export const tableHeads = [
  { id: 0, name: "" },
  { id: 1, name: "Id" },
  { id: 13, name: "Action" },
  { id: 2, name: "Full Name" },
  { id: 3, name: "Recipient" },
  { id: 4, name: "Status" },
  { id: 14, name: "Comment" },
  { id: 7, name: "Order Number" },
  { id: 5, name: "Tracking Number" },
  { id: 8, name: "Product" },
  { id: 9, name: "Total Price" },
  { id: 10, name: "Created Date" },
  { id: 11, name: "Updated Date" },
  { id: 12, name: "Purchased Date" },
];
