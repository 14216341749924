export const editDataInputs = [
  {
    id: 1,
    label: "Price Confirmed",
    name: "confirmed_price",
    value: "",
    type: "number",
  },
  {
    id: 2,
    label: "Shipping Price",
    name: "shipping_price",
    value: "",
    type: "number",
  },
  {
    id: 3,
    label: "Commission ($)",
    name: "commission",
    value: "",
    type: "number",
  },
];
