import { React, useEffect, useState } from "react";
import { Select, Button } from "antd";
import { Chart } from "react-google-charts";
import Highcharts from "react-highcharts";

import { useSelector, useDispatch } from "react-redux";

import styles from "./Statistics.module.scss";

import { getOrgers } from "../../store/actions/index";
import { RangeDate, PieChart } from "../../components";
import { StatisticsWrapper, LineChart } from "../../components/index";
import { statisticsStatuses } from "../../utils/statisticsStatuses";

import arrowDown from "../../assets/svg/arrow_down.svg";
import Shops from "../../components/statisticsDatas/Shops";

import { getReport, getReportBalance } from "../../store/actions/index";
import { getPercent, translateNumber } from "../../utils/helper";

const { Option } = Select;

const Statistics = () => {
  let itemCount = {};
  let created_dates = {};
  let updated_dates = {};
  let purchased_dates = {};
  let cards = {};

  const data2 = [
    ["Task", "Hours per Day"],
    ["Unable to make purchases from this site", 11],
    ["Product no longer available!", 2],
    ["CoOthermmute", 2],
  ];

  const pieOptions = {
    title: "",
    pieHole: 0.5,
    slices: [
      {
        color: "#8781f7",
      },
      {
        color: "#fb4ed5",
      },
      {
        color: "#6b4da3",
      },
    ],
    pieStartAngle: 136,

    legend: {
      position: "legend",
      textStyle: {
        color: "233238",
        fontSize: 14,
      },
    },

    chartArea: {
      left: "-12px",
      top: "-27px",
      width: "100%",
      height: "100%",
    },
    fontName: "Monserat",
  };

  // const options2 = {
  //   legend: "legend",
  //   pieStartAngle: 136,
  //   pieHole: 0.3,
  //   is3D: false,
  //   slices: {
  //     0: { color: "#8781f7" },
  //     1: { color: "#fb4ed5" },
  //     2: { color: "#6b4da3" },
  //   },
  // };
  const [active, setActive] = useState(1);
  const [cardValue, setCardValue] = useState("Armenian card");
  const [dateValue, setDateValue] = useState("Created At");

  const data = useSelector((state) => state.OrdersReducer.orders);
  const perPage = useSelector((state) => state.OrdersReducer.perPage);

  const commissionData = useSelector((state) => state.OrdersReducer.commission);
  const totalData = useSelector((state) => state.OrdersReducer.total);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrgers(perPage, 1, "", "perPage"));
  }, [perPage]);

  const handleReport = () => {
    dispatch(getReport());
  };

  const handleReportBalance = () => {
    dispatch(getReportBalance());
  };

  const handleChangeActive = (id) => {
    setActive(id);
  };

  const statuses = statisticsStatuses.map(({ id, status }) => {
    return (
      <div
        key={id}
        className={
          active == id
            ? styles.statistics__wrapper_bfm__statuses_items_active
            : styles.statistics__wrapper_bfm__statuses_items
        }
      >
        <p onClick={() => handleChangeActive(id)}>{status}</p>
      </div>
    );
  });

  data.forEach(
    ({ shop_name, created_at, updated_at, buy_at, card, cancel_message }) => {
      if (cancel_message !== null) {
      }

      //card
      if (card !== null) {
        if (card in cards) {
          cards[card] = cards[card] + 1;
        } else {
          cards[card] = 1;
        }
      }
      //shop
      if (shop_name in itemCount) {
        itemCount[shop_name] = itemCount[shop_name] + 1;
      } else {
        itemCount[shop_name] = 1;
      }
      //created_at
      let day = new Date(created_at).getDate();
      if (day <= 9) {
        day = "0" + day;
      }
      let month = new Date(created_at).getMonth() + 1;
      if (month <= 9) {
        month = "0" + month;
      }
      let year = new Date(created_at).getFullYear();

      const createdDate = `${day}/${month}/${year}`;
      if (createdDate in created_dates) {
        created_dates[createdDate] = created_dates[createdDate] + 1;
      } else {
        created_dates[createdDate] = 1;
      }

      //updated_at
      let up_day = new Date(updated_at).getDate();
      if (up_day <= 9) {
        up_day = "0" + up_day;
      }
      let up_month = new Date(updated_at).getMonth() + 1;
      if (up_month <= 9) {
        up_month = "0" + up_month;
      }

      let up_year = new Date(updated_at).getFullYear();

      const updatedDate = `${up_day}/${up_month}/${up_year}`;
      if (updatedDate in updated_dates) {
        updated_dates[updatedDate] = updated_dates[updatedDate] + 1;
      } else {
        updated_dates[updatedDate] = 1;
      }

      //purchased_at
      if (buy_at !== null) {
        let p_day = new Date(buy_at).getDate();
        if (p_day <= 9) {
          p_day = "0" + p_day;
        }
        let p_month = new Date(buy_at).getMonth() + 1;
        if (p_month <= 9) {
          p_month = "0" + p_month;
        }
        let p_year = new Date(buy_at).getFullYear();

        const purchasedDate = `${p_day}/${p_month}/${p_year}`;
        if (purchasedDate in purchased_dates) {
          purchased_dates[purchasedDate] = purchased_dates[purchasedDate] + 1;
        } else {
          purchased_dates[purchasedDate] = 1;
        }
      }
    }
  );

  const itemCountArray = Object.entries(itemCount).map(([item, index]) => {
    return index;
  });

  const createdDatesArrayCount = Object.entries(created_dates).map(
    ([item, index]) => {
      return index;
    }
  );

  const createdDatesArrayDate = Object.entries(created_dates).map(
    ([item, index]) => {
      return item;
    }
  );

  const ordersByPercent = (data.length * 1000) / 100;
  const commissionByPercent = (Math.round(commissionData) * 10000) / 100;
  const priceByPercent = (Math.round(totalData) * 100000) / 100;

  const options = {
    chart: {
      type: "spline",
    },
    xAxis: {
      categories: createdDatesArrayDate,
    },
    title: {
      text: "created dates",
    },
    series: [
      {
        data: createdDatesArrayCount,
      },
    ],
  };

  const data1 = [
    [
      "Element",
      "Density",
      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ],
    ["Copper", 8.94, "#5dba2f", null],
    ["Silver", 10.49, "#5dba2f", null],
    ["Gold", 19.3, "color:#5dba2f", null],
    ["Platinum", 21.45, "color: #5dba2f", null],
  ];

  const config = {
    chart: {
      type: "bar",
      height: 241,
    },
    tooltip: {
      useHtml: true,
      format: <h1>Hi</h1>,
    },
    xAxis: {
      categories: [
        "Armenian Card",
        "American Card ",
        "Russian Card ",
        "PayPal Card ",
      ],
      tickLength: 20,
      lineWidth: 0,
    },
    colors: ["#5dba2f"],
    title: null,
    plotOptions: {
      series: {
        pointPadding: 0,
      },
    },
    series: [
      {
        data: [+cards.armenian, +cards.american, +cards.russian, +cards.paypal],
      },
    ],
  };

  return (
    <section className={styles.statistics}>
      <div className={` ${styles.statistics__wrapper}`}>
        <div className={styles.statistics__wrapper_picker}>
          <RangeDate />
          <img
            src={arrowDown}
            alt=""
            className={styles.statistics__wrapper_image}
          />
        </div>

        <div className={styles.statistics__wrapper_lineFirst}></div>
        <div className={styles.statistics__wrapper_item}>
          <Select defaultValue={cardValue}>
            <Option value="armenian_card">Armenian card</Option>
            <Option value="american_card">American card</Option>
            <Option value="russian_card">Russsian card</Option>
            <Option value="paypal_card">Paypay card</Option>
          </Select>
        </div>
        <div className={styles.statistics__wrapper_line}></div>

        <div className={styles.statistics__wrapper_item}>
          <Select defaultValue={dateValue}>
            <Option value="created_at">Created At</Option>
            <Option value="updated_at">Updated At</Option>
            <Option value="buy_at">Buy at</Option>
          </Select>
        </div>
        <div className={styles.statistics__wrapper_line}></div>

        <button
          onClick={handleReport}
          className={styles.statistics__wrapper_item__buttons}
        >
          Report
        </button>
        <button
          onClick={handleReportBalance}
          className={styles.statistics__wrapper_item__buttons}
        >
          Report Balance
        </button>
      </div>

      <StatisticsWrapper
        className={styles.statistics__wrapper_bfm}
        title={"Buy For Me"}
      >
        <div className={styles.statistics__wrapper_bfm__statuses}>
          {statuses}
        </div>
        <LineChart></LineChart>
      </StatisticsWrapper>

      <StatisticsWrapper
        className={styles.statistics__wrapper_shops}
        title={"Shopes"}
      >
        {<Shops itemCount={itemCount} />}
      </StatisticsWrapper>

      <div className={styles.statistics__wrapper_pie}>
        <StatisticsWrapper className={styles.statistics__wrapper_pie_chartP}>
          <div className={styles.statistics__wrapper_pie_list}>
            <PieChart
              color="#5DBA2F"
              bigNumber={1000}
              total={data.length}
              percent={`${getPercent(+ordersByPercent)}%`}
              chartTitle="Total Orders"
            ></PieChart>

            <div className={styles.statistics__wrapper_pie_list_line}></div>

            <PieChart
              color="#5DBA2F"
              bigNumber={100000}
              total={totalData}
              percent={`${getPercent(+priceByPercent)}%`}
              chartTitle="Total Price"
            ></PieChart>

            <div className={styles.statistics__wrapper_pie_list_line}></div>

            <PieChart
              bigNumber={100000}
              color="#FD8945"
              total={commissionData}
              percent={`${getPercent(+commissionByPercent)}%`}
              chartTitle="Commission"
            ></PieChart>
          </div>
        </StatisticsWrapper>
        <StatisticsWrapper
          className={styles.statistics__wrapper_pie_rejection}
          title={"rejection"}
        >
          <Chart
            chartType="PieChart"
            // width="100%"
            // height="185px"
            data={data2}
            options={pieOptions}
          />
        </StatisticsWrapper>
      </div>

      <div>
        <StatisticsWrapper
          title={"Bank Card"}
          className={styles.statistics__wrapper_card}
        >
          <Highcharts config={config} />
        </StatisticsWrapper>
      </div>

      <div className={styles.statistics_card}></div>
    </section>
  );
};

export default Statistics;
