import styles from "./statisticsWrapper.module.scss";

const StatisticsWrapper = ({ title, className, children }) => {
  return (
    <div className={className}>
      <div className={styles.statisticsWSrapper_title}>{title}</div>
      {children}
    </div>
  );
};

export default StatisticsWrapper;
