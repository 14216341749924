import {
  GET_EDITED_ORDERS,
  GET_ORDERS,
  REJECT_ORDER,
  PURCHASE_DATA,
  PER_PAGE,
  SEARCH_ORDER,
  GET_EDITED_ORDERS_ERROR,
  UPDATE_ORDERS,
  GET_INVOICE,
  UPDATE_ORDER_SUCCESS,
} from "../types";

const initialState = {
  orders: [],
  invoice: "",
  editedOrders: {},
  total: 0,
  commission: 0,
  total_page: 0,
  perPage: 50,
  successMessage: "",
  errorMessage: "",
  rejectData: {},
  purchasedData: {},
};

export const OrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        total: action.payload.data.total,
        total_page: action.payload.data.orders.meta.total,
        orders: action.payload.data.orders?.data,
        commission: action.payload.data.commission,
      };
    case UPDATE_ORDERS:
      return {
        ...state,
        orders: action.payload.data?.orders?.data.reverse(),
        successMessage: action.payload.successMessage,
      };

    case UPDATE_ORDER_SUCCESS: {
      return {
        ...state,
        successMessage: action.payload,
      };
    }

    case GET_EDITED_ORDERS_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case GET_EDITED_ORDERS:
      return {
        ...state,
        editedOrders: action.payload,
      };
    case REJECT_ORDER:
      return { ...state, rejectData: action.payload };

    case PURCHASE_DATA:
      return {
        ...state,
        purchasedData: action.payload,
      };

    case PER_PAGE:
      return {
        ...state,
        perPage: action.payload,
      };
    case SEARCH_ORDER:
      return {
        ...state,
        orders: action.payload,
      };

    case GET_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };

    default:
      return state;
  }
};
