import { useState, useRef, useEffect } from "react";
import "antd/dist/antd.css";
import { useDispatch, useSelector } from "react-redux";
import "./BuyForMeTable.scss";
import { Button } from "../index";
import { Link } from "react-router-dom";
import TableItem from "./tableItem/TableItem";
import {
  getOrgers,
  setPerPage,
  searchOrder,
  buyOrder,
  rejectOrder,
} from "../../store/actions/";
import { PaginationGroup } from "../index";
import { filterCard, filterStatus } from "../../utils/filterStatus";
import { Checkbox, DatePicker, Space, Select } from "antd";
import AlertDialog from "../../components/dialog/Dialog";

const { Option } = Select;

const BuyForMeTable = ({
  title,
  rows,
  tableHeads,
  setReady,
  action,
  updateData,
  dialog,
  setDialog,
  handleAgree,
  ready,
  handleClose,
  checkedValues,
  setCheckedValues,
}) => {
  const [data, setData] = useState({});
  const [codeData, setCodeData] = useState({});
  const [value, setValue] = useState("");
  const [state, setState] = useState(0);
  const [dates, setDates] = useState({});
  const [filterBy, setFilterBy] = useState("created_at");
  const [buyIds, setBuyIds] = useState({ id: [] });
  const [rejectIds, setRejectIds] = useState({ id: [] });
  const [isFilter, setIsFilter] = useState(false);

  const orders = useSelector((state) => state.OrdersReducer.orders);
  const perPage = useSelector((state) => state.OrdersReducer.perPage);
  const orderData = useSelector((state) => state.OrdersReducer.orders);

  const dispatch = useDispatch();

  const onChangeChecked = (value, index) => {
    const updatedState = rows[index];
    delete updatedState.other;

    setState((pre) => {
      return pre + 1;
    });

    if (!checkedValues.includes(value)) {
      setCheckedValues([...checkedValues, value]);
    } else {
      const indx = checkedValues.findIndex((item) => value == item);

      checkedValues.splice(indx, 1);
      setCheckedValues(checkedValues);
    }
  };

  const onChangeStatus = (checkedValues) => {
    setData({ status: [...checkedValues] });
  };

  const onChangeCard = (checkedValues) => {
    setCodeData({ card: [...checkedValues] });
    setIsFilter(true);
  };

  const searchByStatus = (e) => {
    dispatch(getOrgers(perPage, 1, data, "status"));
    setIsFilter(true);
  };

  const searchByCode = (e) => {
    dispatch(getOrgers(perPage, 1, codeData, "code"));
  };

  const handleClick = (index) => {
    const updatedState = rows[index];

    if (updatedState.other) {
      delete updatedState.other;
      setState((pre) => {
        return pre + 1;
      });
    } else {
      updatedState.other = {
        description: "Hello there",
      };
      setState((pre) => {
        return pre + 1;
      });
    }
  };

  const buyAll = () => {
    // setDialog({
    //   isOpen: true,
    //   message: "Are you sure you want to buy? 😊",
    //   rejectMessage: false,
    //   type: "buy",
    // });
    // setBuyIds({ id: checkedValues });
    // setTimeout(() => {
    // }, 1000);

    // setBuyIds({ id: [] });
    dispatch(buyOrder({ id: checkedValues }));
    setReady(true);
    setCheckedValues([]);
  };

  const rejectAll = (passData, type) => {
    setDialog({
      isOpen: true,
      message: "Are you sure you want to reject? 😏",
      rejectMessage: true,
      type: "reject",
    });
  };

  const changePerPage = (e) => {
    if (e.target.value == "all") {
      dispatch(getOrgers(1000000, "", 1, "perPage"));
      dispatch(setPerPage(orderData?.length));
    } else {
      dispatch(setPerPage(e.target.value));
    }
  };

  const onChange = (e) => {
    const { value } = e.target;
    setValue(value.toLowerCase());
  };

  const searchHendler = (e) => {
    e.preventDefault();
    const searchedOrder = orders.filter((order) => {
      if (order.admin_comment) {
        if (order.admin_comment.toLowerCase().includes(`${value}`)) {
          return order;
        }
      }
      if (order.order_number !== null) {
        if (order.order_number.toLowerCase().includes(`${value}`)) {
          return order;
        }
      }

      if (
        order.customer_full_name.toLowerCase().includes(value.toLowerCase())
      ) {
        return order;
      }

      if (order.product.name.toLowerCase().includes(value.toLowerCase())) {
        return order;
      }

      if (
        order.tracking_code
          ?.toLocaleLowerCase()
          .includes(value.toLocaleLowerCase())
      ) {
        return order;
      }
    });
    dispatch(searchOrder(searchedOrder));
  };

  const handleChangeDateFilter = (value) => {
    setFilterBy(value);
  };

  const handleReset = (e) => {
    e.preventDefault();
    dispatch(getOrgers(perPage, 1, "", "perPage"));
    setValue("");
  };

  const onChangeCreatedAt = (date, dateString) => {
    setDates({ ...dates, start_at: dateString });
  };

  const onChangeUpdatedAt = (date, dateString) => {
    setDates({ ...dates, end_at: dateString });
  };
  const filterByDate = () => {
    console.log("oikk")
    dates.format = filterBy;
    // const newFilterByDate = {"date[]": dates}
    // console.log(newFilterByDate);
    dispatch(
      getOrgers(perPage, 1, dates, "dateFilter")
    );
    setDates({});
  };

  return (
    <div className="buyForMeTable-wrapper">
      <div className={"buyForMeTable-header"}>
        <div align="left" className={"buyForMeTable-title"}>
          <h2>{title}</h2>
        </div>

        <div align="left" className={"buyForMeTable-link-wrapper"}>
          {!!action.addUrl && (
            <Link to={action.addUrl} className={"buyForMeTable-link"}>
              {action.addLinkName}
            </Link>
          )}
        </div>
      </div>
      <form className="dataPicker_wrapper">
        <Space direction="horizontal">
          <DatePicker
            format="YYYY-MM-DD"
            onChange={onChangeCreatedAt}
            placeholder="From"
          />
          <DatePicker
            format="YYYY-MM-DD"
            onChange={onChangeUpdatedAt}
            placeholder="To"
          />

          <Select
            onChange={handleChangeDateFilter}
            value={filterBy}
            style={{
              width: "100%",
            }}
          >
            <Option value="created_at">created At</Option>
            <Option value="updated_at">Updated At</Option>
            <Option value="buy_at">Buy At</Option>
            <Option value="deleted_at">Deleted At</Option>
          </Select>
          <Button
            type="submit"
            className="search_wrapper__items_button date_picker"
            onClick={filterByDate}
            name={"Filter"}
          />
        </Space>
      </form>

      <div className="buy-for-me-filters-wrapper">
        <form className="buy-for-me-filters" action="">
          <Checkbox.Group
            className="buy-for-me-filter-checkbox "
            options={filterStatus}
            onChange={onChangeStatus}
          />
          <div className="form-group">
            <Button
              className="search_wrapper__items_button"
              onClick={searchByStatus}
              name={"Filter"}
            />
          </div>
        </form>

        <form className="buy-for-me-filters">
          <Checkbox.Group
            options={filterCard}
            onChange={onChangeCard}
            defaultValue={["armenian"]}
          />

          <div className="form-group">
            <Button
              className="search_wrapper__items_button"
              onClick={searchByCode}
              name={"Filter"}
            />
          </div>
        </form>
      </div>
      <div className="search_wrapper">
        <div className="search_wrapper__items">
          <form action="">
            <input
              value={value}
              onChange={onChange}
              placeholder="Search"
              type="text"
              className="search_wrapper__items_input"
            />
            <button
              type="submit"
              onClick={searchHendler}
              className="search_wrapper__items_button"
            >
              Search
            </button>
            <button
              onClick={handleReset}
              className="search_wrapper__items_button_reset"
            >
              Reset
            </button>
          </form>
        </div>
        <>
          <select
            name="per page"
            onClick={changePerPage}
            className="search_wrapper__select"
          >
            <option value="50">50</option>
            <option value="all">All</option>
            <option value="10">10</option>
            <option value="20">20</option>
          </select>
        </>
      </div>
      {!!rows?.length ? (
        <div className="table--wrapper">
          <div className="table--wrapper-head">
            <p className={"table-items-count"}>Total Items {rows?.length}</p>

            <div
              className={
                checkedValues.length == 0
                  ? `table--wrapper-head-buttons`
                  : `table--wrapper-head-buttons-block`
              }
            >
              <button
                onClick={buyAll}
                className="table--wrapper-head-buttons--item"
              >
                Buy All
              </button>
              <button
                onClick={rejectAll}
                className="table--wrapper-head-buttons--item"
              >
                Reject All
              </button>
            </div>
          </div>
          <>
            {dialog.isOpen && (
              <div className="mat-dialog-wrapper">
                <AlertDialog
                  checkedValues={checkedValues}
                  open={dialog.isOpen}
                  handleClose={handleClose}
                  message={dialog.message}
                  handleAgree={handleAgree}
                  rejectMessage={dialog.rejectMessage}
                  type={dialog.type}
                />
              </div>
            )}
          </>

          <TableItem
            handleAgree={handleAgree}
            checkedValues={checkedValues}
            setCheckedValues={setCheckedValues}
            ready={ready}
            onChangeChecked={onChangeChecked}
            rows={rows}
            action={action}
            handleClick={handleClick}
            tableHeads={tableHeads}
            updateData={updateData}
          />
          <PaginationGroup
            isFilter={isFilter}
            data={
              data?.status?.length > 0
                ? data
                : codeData?.card?.length > 0
                ? codeData
                : ""
            }
            type={
              data?.status?.length > 0
                ? "status"
                : codeData?.card?.length > 0
                ? "code"
                : "perPage"
            }
          />
        </div>
      ) : (
        <h1>No Result</h1>
      )}
    </div>
  );
};

export default BuyForMeTable;
