import "./editOrder.scss";
import { Image } from "antd";
import { Alert, Stack } from "@mui/material";
import { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components/index";
import pdf from "../../../assets/svg/pdf_format.svg";
import close from "../../../assets/svg/close1.svg";
import Star from "../../../assets/svg/star.svg";
import {
  editOrders,
  getEditedOrdersUpdate,
  uploadFile,
} from "../../../store/actions/ordersAction";
import { editDataInputs } from "../../../utils/editDataInputs";
import { cardOptionsData } from "../../../utils/cardOptionsData";
import { Select } from "antd";
import { translateNumber } from "../../../utils/helper";
import arrow from "../../../assets/svg/arrow_down.svg";

const { Option } = Select;

function EditOrder() {
  const data = useSelector((state) => state.OrdersReducer.editedOrders);
  const invoice = useSelector((state) => state.OrdersReducer.invoice);
  const ordersList = useSelector((state) => state.OrdersReducer.orders);
  const success = useSelector((state) => state.OrdersReducer.successMessage);
  const errorMessage = useSelector((state) => state.OrdersReducer.errorMessage);

  const [value, setVal] = useState("");
  const [isReserve, setIsReserve] = useState(false);
  const [isImg, setIsImg] = useState(false);
  const [firstCheck, setFirstCheck] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [isInvoice, setIsInvoice] = useState(true);
  const [isChoosed, setIsChoosed] = useState(true);
  const [isSelectedOption, setIsSelectedOption] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [totalPrice, setTotalPrice] = useState(null);
  const [alert, setAlert] = useState({ success: false, error: false });
  const [invoicePrice, setInvoicePrice] = useState(null);
  const [secondData, setSecondData] = useState("armenian");
  const [orderNumber, setOrderNumber] = useState("");
  const [values, setValue] = useState({
    confirmed_price: data?.confirmed_price,
    shipping_price: data?.shipping_price != null ? data?.shipping_price : 0,
    commission: data?.commission,
    quantity: data.product?.quantity,
  });
  const [dataForm, setdataForm] = useState({
    price: +data?.product?.price,
    quantity: +data.product?.quantity,
    color: data.product?.color,
    size: data.product?.size,
    confirmed_price: values.confirmed_price,
    shipping_price: values.shipping_price != null ? values.shipping_price : 0,
    commission: values.commission !== null || data.commission,
    // admin_comment: data?.admin_comment,
    rate: 1,
    card:
      isSelectedOption == false
        ? secondData
        : data.card == null
        ? "armenian"
        : data.card,

    delivery_id: 1,
    total_price: translateNumber(+totalPrice) || +data.total_price,
  });


  const navigate = useNavigate();

  const formData = new FormData();

  const dispatch = useDispatch();

  let { id } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [errorMessage, success]);

  useEffect(() => {
    if (Object.keys(data).length === 0) {
      dispatch(getEditedOrdersUpdate(id));
      setIsImg(false);
      setSecondData(data.card);
    }

    const newPrice =
      (+data?.confirmed_price * +data?.product?.quantity +
        +data?.shipping_price) *
        1 +
      +data?.commission;

    setTotalPrice(translateNumber(+newPrice));
  }, [
    data,
    dispatch,
    id,
    values?.commission,
    values?.confirmed_price,
    values?.shipping_price,
  ]);

  useEffect(() => {
    const newPrice =
      (+values?.confirmed_price * +data?.product?.quantity +
        +values?.shipping_price) *
        1 +
      +values?.commission;
    setTotalPrice(+newPrice);
    setdataForm({
      price: +data.product?.price,
      quantity: data.product?.quantity,
      color: data.product?.color,
      size: data.product?.size,
      confirmed_price: values.confirmed_price
        ? values.confirmed_price
        : data.confirmed_price,
      shipping_price: values.shipping_price
        ? values.shipping_price
        : data.shipping_price || 0,
      commission: values.commission || data.commission,
      rate: 1,
      card:
        isSelectedOption == false
          ? secondData
          : data.card == null
          ? "armenian"
          : data.card,

      delivery_id: 1,
      total_price: newPrice || data.total_price,
      file: data.file,
    });
  }, [
    secondData,
    data.file,
    values,
    data.rate,
    data.product?.price,
    data.product?.quantity,
    data.product?.color,
    data.product?.size,
    data.card,
    data.tracking_code,
    data.shop_name,
    data.quantity,
    isSelectedOption,
    data.confirmed_price,
    data.shipping_price,
    data.commission,
    data.total_price,
    value.commission,
  ]);

  useMemo(() => {
    if (data.data?.status === 200 && firstCheck) {
      setAlert({ success: true, error: false });
      setTimeout(() => {
        navigate("/order");
      }, 1500);
    } else {
      if (!!data.data?.errors) {
        setAlert({ success: false, error: true });
        setTimeout(() => {
          setAlert({ success: false, error: false });
        }, 2000);
      }
    }
  }, [data]);

  const handleChange = (e) => {
    setSelectedFile({ file: e.target.files[0] });
    setIsChoosed(true);

    formData.append("file", e.target.files[0]);
    dispatch(uploadFile(formData));
  };

  const changeInputValue = (e) => {
    const { value, name } = e.target;
    if (name === "confirmed_price") {
      setValue({ ...values, [name]: value });
    } else if (name === "shipping_price") {
      setValue({ ...values, [name]: value });
    } else if (name === "commission") {
      setValue({ ...values, [name]: value });
    }
  };

  const updateOnly = (e) => {
    e.preventDefault();
    setFirstCheck(true);
    dispatch(
      editOrders(
        {
          invoice: isChoosed == false ? null : invoice || data.invoice,
          admin_comment: commentValue,
          invoice_price: invoicePrice ? invoicePrice : data.invoice_price,
          tracking_code: value || data.tracking_code,
          order_number: orderNumber || data.order_number,
          card: isSelectedOption == true ? secondData : data.card,
          ...dataForm,
        },
        id,
        ordersList,
        navigate,
        "only"
      )
    );
  };

  const updateWithNotify = (e) => {
    e.preventDefault();
    setFirstCheck(true);
    dispatch(
      editOrders(
        {
          admin_comment: commentValue && commentValue,
          ...dataForm,
        },
        id,
        ordersList,
        navigate,
        "notify"
      )
    );
  };

  const capitalize = (word) => {
    return (
      word && word.replace(/\w/, (firstLetter) => firstLetter.toUpperCase())
    );
  };

  const handleClose = (e) => {
    e.preventDefault();
    setIsInvoice(!isInvoice);
    setIsChoosed(false);
  };

  const back = () => {
    navigate("/buy-for-me");
  };
  const setDatainSelect = (value) => {
    setSecondData(value);
    setIsReserve(!isReserve);
    setIsSelectedOption(!isSelectedOption);
  };
  // console.log(data.commission);
  const editInputs = editDataInputs.map(({ id, label, name, type }) => {
    return (
      <div className="order-details-body-input-wrapper" key={id}>
        <label htmlFor="" className="order-details-body-label">
          {label}
        </label>

        {Object.keys(data).length === 0 ? (
          <>
            <input
              onWheel={(e) => e.target.blur()}
              onChange={changeInputValue}
              type="number"
              name={name}
              className="order-details-body-input"
              defaultValue={
                name === "confirmed_price"
                  ? data.confirmed_price
                  : name === "shipping_price"
                  ? data.shipping_price
                  : name === "commission"
                  ? data.commission
                  : 0
              }
              placeholder={
                name === "confirmed_price" && +data?.confirmed_price
                  ? null
                  : name == "shipping_price" && data.shipping_price
                  ? null
                  : name == "commission" && data.commission
                  ? null
                  : 0
              }
            />
          </>
        ) : (
          <input
            onWheel={(e) => e.target.blur()}
            onChange={changeInputValue}
            type="number"
            name={name}
            className="order-details-body-input"
            defaultValue={
              name === "confirmed_price"
                ? data.confirmed_price
                : name === "shipping_price"
                ? data.shipping_price
                : name === "commission"
                ? data.commission
                : 0
            }
            placeholder={
              name === "confirmed_price" && +data?.confirmed_price
                ? null
                : name == "shipping_price" && data.shipping_price
                ? null
                : name == "commission" && data.commission
                ? null
                : 0
            }
          />
        )}
      </div>
    );
  });

  const ref = useRef();
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsReserve(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  return (
    <>
      {!!data && (
        <div className={"edit-order-wrapper"}>
          {success && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="success">{success}!</Alert>
            </Stack>
          )}
          {errorMessage && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">{errorMessage}!</Alert>
            </Stack>
          )}
          <div className="alert-wrapper"></div>
          <div className="edit-order-header">
            <div className="back-btn-wrapper">
              <Button className="back-btn" onClick={back} name={"Back"} />
            </div>
            <div>
              <div className="edit_order_wrapper">
                <h4 className={"edit-order-title"}>Edit Order</h4>
                <span
                  className={
                    data.status != "need to confirm"
                      ? `status_${data.status}`
                      : `status_need`
                  }
                >
                  {capitalize(data.status)}
                </span>
              </div>
              <div>
                {data.customer_is_prime == 0 ? (
                  <span className="edit-order-subtitle">
                    {data.customer_full_name}
                  </span>
                ) : (
                  <span>
                    {data.customer_full_name}
                    <img src={Star} alt="Prime User" className="prime_user" />
                  </span>
                )}

                {data.customer_full_name != data.recipient_full_name && (
                  <p>Recipient: {data.recipient_full_name}</p>
                )}
              </div>
              <div style={{ display: "flex", marginTop: 20 }}>
                {data.created_at && (
                  <p className="edit-form-title-wrapper-coupon created_date">
                    Created Date: {data.created_at}
                  </p>
                )}
                {data.updated_at && (
                  <p className="edit-form-title-wrapper-coupon">
                    Updated Date: {data.updated_at}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="edit-order-form-wrapper">
            <div className="edit-form-title-wrapper">
              <div className="shop-img-wrapper">
                {data.shop_name ? (
                  <img
                    src={`/shop/${capitalize(data.shop_name)}.svg`}
                    alt={data.shop_name}
                  />
                ) : (
                  ""
                )}
              </div>
              <a
                className="edit-form-title"
                target={"_blank"}
                href={data?.product?.url}
                rel="noreferrer"
              >
                {data?.product?.name}
              </a>

              {data.coupon && (
                <p className="edit-form-title-wrapper-coupon">
                  Coupon: {data.coupon}
                </p>
              )}
            </div>
            <form ref={ref} className="edit-order-form">
              {data.status === "purchased" && (
                <div className="order-details-wrapper purchased-details-wrapper">
                  <div className="order-details-header">
                    <h2 className="order-details-title">
                      Purchased Order Details
                    </h2>
                  </div>
                  <div className="order-details-body">
                    <div className="order-details-body-inputs-wrapper purchased-details-body-inputs">
                      <div className="order-details-body-input-wrapper">
                        <label htmlFor="" className="order-details-body-label">
                          Tracking
                        </label>
                        <input
                          type="text"
                          defaultValue={value ? value : data.tracking_code}
                          className="order-details-body-input"
                          onChange={(e) => {
                            setVal(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="order-details-body-inputs-wrapper purchased-details-body-inputs">
                      <div className="order-details-body-input-wrapper">
                        <label htmlFor="" className="order-details-body-label">
                          Order Number
                        </label>
                        <input
                          placeholder="Order Number"
                          type="text"
                          defaultValue={
                            orderNumber ? orderNumber : data.order_number
                          }
                          className="order-details-body-input"
                          onChange={(e) => {
                            setOrderNumber(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="order-details-body-inputs-wrapper purchased-details-body-inputs">
                      <div className="order-details-body-input-wrapper">
                        <label
                          htmlFor="currency"
                          className="order-details-body-label "
                        >
                          Currency
                          <select
                            name="currency"
                            className={"order-details-body-input-select"}
                            defaultValue={data.currency}
                            onChange={(e) => {
                              setdataForm({
                                ...dataForm,
                                currency: e.target.value,
                              });
                            }}
                          >
                            <option value="$">$</option>
                          </select>
                        </label>

                        <input
                          defaultValue={
                            invoicePrice ? invoicePrice : data.invoice_price
                          }
                          onChange={(e) => {
                            setInvoicePrice(e.target.value);
                          }}
                          id="currency"
                          type="text"
                          className="order-details-body-input"
                          placeholder="Price from invoice"
                        />
                      </div>
                    </div>
                    <div className="order-details-body-inputs-wrapper purchased-details-body-inputs">
                      <div className="order-details-body-input-wrapper">
                        {isInvoice == true && isChoosed == true ? (
                          data.invoice.slice(-3) == "pdf" ? (
                            <div className="invoice_wrapper">
                              <div class="files__formats_item">
                                <a href={data.invoice} target="_blank">
                                  <img
                                    src={pdf}
                                    alt="pdf"
                                    class="files__formats_images"
                                  />
                                </a>
                              </div>

                              <img
                                onClick={handleClose}
                                src={close}
                                alt="close"
                                className="close_image"
                              />
                            </div>
                          ) : data.invoice.slice(-3) == "jpg" ||
                            data.invoice.slice(-3) == "jpg" ||
                            data.invoice.slice(-3) == "png" ? (
                            <div className="invoice_wrapper">
                              <Image width={150} src={data.invoice} />

                              <img
                                src={close}
                                onClick={handleClose}
                                alt="close"
                                className="close_image"
                              />
                            </div>
                          ) : data.invoice.slice(-3) == "txt" ? (
                            <div style={{ display: "flex" }}>
                              <a
                                href={data.invoice}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Embedded Text Document
                              </a>
                              <img
                                src={close}
                                onClick={handleClose}
                                alt="close"
                              />
                            </div>
                          ) : (
                            <label htmlFor={1}>
                              <input
                                onChange={handleChange}
                                type="file"
                                accept=" image/jpeg application/pdf"
                                id={1}
                              />
                              <img
                                onClick={handleClose}
                                src={close}
                                alt="close"
                                className="close_image"
                              />
                            </label>
                          )
                        ) : selectedFile == null ? (
                          <>
                            <label htmlFor={1}>
                              <input
                                onChange={handleChange}
                                type="file"
                                accept=" image/jpeg application/pdf"
                                id={1}
                              />
                            </label>
                          </>
                        ) : (
                          <>
                            <label htmlFor={1}>
                              <input
                                onChange={handleChange}
                                type="file"
                                accept=" image/jpeg application/pdf"
                                id={1}
                              />
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="order-details-wrapper">
                <div className="order-details-header">
                  <h2 className="order-details-title">Order Details</h2>
                </div>
                <div className="order-details-body">
                  <div className="order-details-body-inputs-wrapper">
                    <div className="order-details-body-input-wrapper">
                      <label htmlFor="" className="order-details-body-label">
                        Price
                      </label>
                      <input
                        type="text"
                        className="order-details-body-input"
                        defaultValue={data?.product?.price}
                        disabled
                      />
                    </div>
                    <div className="order-details-body-input-wrapper">
                      <label htmlFor="" className="order-details-body-label">
                        Color
                      </label>
                      <input
                        type="text"
                        className="order-details-body-input"
                        defaultValue={data?.product?.color}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="order-details-body-inputs-wrapper">
                    <div className="order-details-body-input-wrapper">
                      <label htmlFor="" className="order-details-body-label">
                        Quantity
                      </label>
                      <input
                        type="text"
                        className="order-details-body-input"
                        defaultValue={data?.product?.quantity}
                        disabled
                      />
                    </div>
                    <div className="order-details-body-input-wrapper">
                      <label htmlFor="" className="order-details-body-label">
                        Warehouse
                      </label>
                      <div className="country-img-wrapper">
                        <div className="country-img">
                          <img
                            src={
                              data?.delivery?.country.toLowerCase() &&
                              require(`../../../assets/svg/flags/${data?.delivery?.country.toLowerCase()}.svg`)
                            }
                            alt=""
                          />
                        </div>
                        <div className="country-img">
                          <img
                            src={require(`../../../assets/images/icons/airplanes.png`)}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="order-details-body-inputs-wrapper">
                    <div className="order-details-body-input-wrapper">
                      <label htmlFor="" className="order-details-body-label">
                        Size
                      </label>
                      <input
                        type="text"
                        className="order-details-body-input"
                        defaultValue={data?.product?.size}
                        disabled
                      />
                    </div>
                    <div className="order-details-body-input-wrapper">
                      <label htmlFor="" className="order-details-body-label">
                        Comment
                      </label>
                      <p>{data.comment ? data?.comment : "—"}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="order-details-wrapper">
                <div className="order-details-header">
                  <h2 className="order-details-title">Cost and Shipping</h2>
                </div>
                <div className="order-details-body">
                  <div className="order-details-body-inputs-wrapper">
                    <div className="order-details-body-input-wrapper">
                      <label htmlFor="" className="order-details-body-label">
                        Currency
                      </label>
                      <input
                        type="text"
                        className="order-details-body-input"
                        value={"$"}
                        disabled
                      />
                    </div>
                    <div className="order-details-body-input-wrapper">
                      <label htmlFor="" className="order-details-body-label">
                        Rate
                      </label>
                      <input
                        type="number"
                        className="order-details-body-input"
                        defaultValue={+data?.rate ? +data?.rate : 1}
                        placeholder={+data?.rate ? null : 0}
                        onChange={(e) => {
                          setdataForm({ ...dataForm, rate: +e.target.value });
                        }}
                        disabled
                      />
                    </div>
                    <div className="order-details-body-input-wrapper">
                      <label htmlFor="" className="order-details-body-label">
                        Bank card
                      </label>
                      <>
                        <div className="card_wrapper">
                          <div
                            onClick={() => {
                              setIsReserve(!isReserve);
                            }}
                            className="card_wrapper_select"
                          >
                            <>
                              <div className="card_wrapper_select_input">
                                {isSelectedOption == false
                                  ? secondData
                                  : data.card == null
                                  ? secondData
                                  : data.card}
                              </div>
                            </>
                            <img
                              src={arrow}
                              alt=""
                              className={
                                isReserve
                                  ? "card_wrapper_select_image_reserve"
                                  : "card_wrapper_select_image"
                              }
                            />
                          </div>

                          <div
                            className={
                              isReserve
                                ? "card_wrapper_option_block"
                                : "card_wrapper_option"
                            }
                          >
                            {cardOptionsData.map((item) => {
                              return (
                                <div
                                  onClick={() => setDatainSelect(item)}
                                  className="card_wrapper_option_items"
                                  key={item}
                                >
                                  {item}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                  <form className="order-details-body-inputs-wrapper">
                    {editInputs}

                    <div className="order-details-body-input-wrapper">
                      <label htmlFor="" className="order-details-body-label">
                        Price Total ($)
                      </label>
                      {isNaN(totalPrice) ? (
                        <>
                          <p style={{ display: "none" }}></p>
                          <input
                            disabled
                            type="number"
                            defaultValue={data.total_price}
                            name="totoalPrice"
                            className="order-details-body-input-total"
                            placeholder={data.total_price ? null : "-"}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            disabled
                            type="number"
                            value={translateNumber(totalPrice)}
                            name="totoalPrice"
                            className="order-details-body-input-total"
                            placeholder={+data.total_price ? null : "-"}
                          />
                        </>
                      )}
                    </div>
                  </form>
                  <div className="order-details-body-inputs-wrapper">
                    <div className="order-details-body-input-wrapper">
                      <label htmlFor="" className="order-details-body-label">
                        Admin comment
                      </label>
                      <textarea
                        onChange={(e) => {
                          setCommentValue(e.target.value);
                        }}
                        defaultValue={
                          commentValue ? commentValue : data.admin_comment
                        }
                        cols={70}
                        rows={6}
                        className="order-details-body-input"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="edit-order-form-btn-wrapper">
                <button
                  className={"edit-order-form-btn update-only"}
                  onClick={updateOnly}
                >
                  Update only
                </button>
                {data.status !== "purchased" && (
                  <button
                    className={"edit-order-form-btn notify"}
                    onClick={updateWithNotify}
                  >
                    Update and Notify user
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default EditOrder;
