export const GET_ORDERS = "GET_ORDERS";
export const GET_INVOICE = "GET_INVOICE";
export const PER_PAGE = "PER_PAGE";
export const LOGIN_USER = "LOGIN_USER";
export const REJECT_ORDER = "REJECT_ORDER";
export const SEARCH_ORDER = "SEARCH_ORDER";
export const GET_DELIVERY = "GET_DELIVERY";
export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const PURCHASE_DATA = "PURCHASE_DATA";
export const GET_PERMISSION = "GET_PERMISSION";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const GET_EDITED_ORDERS = "GET_EDITED_ORDERS";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const GET_EDITED_ORDERS_ERROR = "GET_EDITED_ORDERS_ERROR";
