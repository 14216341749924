import { LOGIN_USER, LOGIN_USER_ERROR } from "../types";

const initialState = {
  isLoggedIn: false,
  successMessage: "",
  errorMessage: "",
  data: [],
};

export const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        data: action.payload,
        isLoggedIn: !state.isLoggedIn,
        successMessage: "SUCCESS!",
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
