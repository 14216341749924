export const filterStatus = [
  { label: "InProcess", value: "inProcess" },
  { label: "NeedsConfirmed", value: "needsConfirm" },
  { label: "Confirmed", value: "confirmed" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Rejected", value: "rejected" },
  { label: "Purchased", value: "purchased" },
  { label: "Delivered", value: "delivered" },
  {label: "Deleted", value: "deleted"}
];

export const filterCard = [
  { label: "Armenian cards", value: "armenian" },
  { label: "USA cards", value: "american" },
  { label: "Russian cards", value: "russian" },
  { label: "Pay Pal", value: "paypal" },
];
