import { useState } from "react";
import Pagination from "react-js-pagination";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getOrgers } from "../../store/actions";
import styles from "./pagination.module.scss";

const PaginationGroup = ({ type, isFilter, data }) => {
  const [activePage, setActivePage] = useState(1);
  const totalPage = useSelector((state) => state.OrdersReducer.total_page);
  const perPage = useSelector((state) => state.OrdersReducer.perPage);

  const dispatch = useDispatch();

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    if (isFilter) {
      dispatch(getOrgers(perPage, pageNumber, data, type));
    } else {
      dispatch(getOrgers(perPage, pageNumber, "", type));
    }
  };
  return (
    <Pagination
      hideDisabled
      nextPageText="Next"
      prevPageText="Previous"
      lastPageText=""
      firstPageText=""
      activePage={activePage}
      totalItemsCount={totalPage}
      onChange={handlePageChange}
      innerClass={styles.container_pagination__list}
      itemClass={styles.container_pagination__list_item}
      linkClass={styles.container_pagination__list_link}
      activeClass={styles.container_pagination__list_active}
      activeLinkClass={styles.container_pagination__list_active_link}
    />
  );
};

export default PaginationGroup;
