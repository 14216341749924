import { AxiosInstance } from "../../utils/AxiosInstance";
import fileDownload from "js-file-download";
import { saveAs } from "file-saver";
import {
  GET_EDITED_ORDERS,
  GET_EDITED_ORDERS_ERROR,
  UPDATE_ORDER_SUCCESS,
  GET_INVOICE,
  GET_ORDERS,
  PER_PAGE,
  PURCHASE_DATA,
  REJECT_ORDER,
  SEARCH_ORDER,
  UPDATE_ORDERS,
} from "../types";

export const getOrgers =
  (perPage, pageNumber, param, type) => async (dispatch) => {
    try {
      switch (type) {
        case "perPage":
          let { data } = await AxiosInstance.get(
            `owner/orders?page=${pageNumber}&per_page=${perPage}`
          );

          dispatch({
            type: GET_ORDERS,
            payload: data,
          });
          break;

        case "status":
          let filterByStatus = await AxiosInstance.get(`owner/orders`, {
            params: {
              page: pageNumber,
              per_page: perPage,
              status: param.status,
            },
          });

          dispatch({
            type: GET_ORDERS,
            payload: filterByStatus.data,
          });
          break;
        case "code":
          let filterByCard = await AxiosInstance.get(`owner/orders`, {
            params: {
              per_page: perPage,
              card: param.card,
            },
          });
          dispatch({
            type: GET_ORDERS,
            payload: filterByCard.data,
          });
          break;

        case "dateFilter":
          let filretByDate = await AxiosInstance.get(`owner/orders`, {
            params: {
              page: pageNumber,
              per_page: perPage,
              "date[]": param
            },
          });
          dispatch({
            type: GET_ORDERS,
            payload: filretByDate.data,
          });
          break;
        default:
          return;
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getReport = () => async () => {
  const { data } = await AxiosInstance.get(`/owner/orders/report`, {
    responseType: "blob",
    method: "GET",
    headers: { "Content-Type": "application/vnd.ms-excel" },
  });

  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "file.xls");
  document.body.appendChild(link);
  link.click();
  // saveAs(data, "example.xlsx");
  // fileDownload(data, "report.xls");
};

export const getReportBalance = () => async (dispatch) => {
  const { data } = await AxiosInstance.get(`/owner/orders/report-balance`, {
    responseType: "blob",
    method: "GET",
    headers: { "Content-Type": "application/vnd.ms-excel" },
  });
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "fileBalance.xls");
  document.body.appendChild(link);
  link.click();
  console.log(data);
};

export const getEditedOrders = (editedData) => async (dispatch) => {
  dispatch({
    type: GET_EDITED_ORDERS,
    payload: editedData,
  });
};

export const getEditedOrdersUpdate = (id) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.get(`/owner/orders/${id}`);
    dispatch({
      type: GET_EDITED_ORDERS,
      payload: data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const editOrders =
  (datas, id, ordersList, navigate, type) => async (dispatch) => {
    if (type === "notify") {
      try {
        const { data } = await AxiosInstance.put(`owner/orders/${id}`, datas);

        const dataIndex = ordersList.findIndex((item) => item.id == data.id);
        ordersList.splice(dataIndex, 1, data.data);
        setTimeout(() => {
          navigate("/buy-for-me");
          dispatch({
            type: UPDATE_ORDER_SUCCESS,
            payload: "",
          });
        }, 2000);

        dispatch({
          type: UPDATE_ORDERS,
          payload: {
            data: data,
            successMessage: "Your order has been successfully edited",
          },
        });
      } catch (error) {
        dispatch({
          type: GET_EDITED_ORDERS_ERROR,
          payload: "Something went wrong!",
        });
        setTimeout(() => {
          dispatch({
            type: GET_EDITED_ORDERS_ERROR,
            payload: "",
          });
        }, 4000);
      }
    } else {
      try {
        const { data } = await AxiosInstance.post(
          `owner/orders/only/${id}`,
          datas,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );
        setTimeout(() => {
          navigate("/buy-for-me");
          dispatch({
            type: UPDATE_ORDER_SUCCESS,
            payload: "",
          });
        }, 2000);
        dispatch({
          type: UPDATE_ORDERS,
          payload: {
            successMessage: "Your order has been successfully edited",
          },
        });
      } catch (error) {
        console.log(error);

        dispatch({
          type: GET_EDITED_ORDERS_ERROR,
          payload: "Something went wrong!",
        });
        setTimeout(() => {
          dispatch({
            type: GET_EDITED_ORDERS_ERROR,
            payload: "",
          });
        }, 4000);
      }
    }
  };

export const rejectOrder = (data) => async (dispatch) => {
  const { message, type } = data.data;
  const { rejectIds } = data;

  try {
    const { data } = await AxiosInstance.post(`owner/orders/reject`, {
      id: rejectIds.id,
      cancel_message: message,
      cancel_message_type: type,
    });
    dispatch({
      type: REJECT_ORDER,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const buyOrder = (id, orders) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.post(`owner/orders/purchase`, id);
    dispatch({
      type: PURCHASE_DATA,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const setPerPage = (number) => (dispatch) => {
  dispatch({
    type: PER_PAGE,
    payload: number,
  });
};
export const searchOrder = (orderedData) => (dispatch) => {
  dispatch({
    type: SEARCH_ORDER,
    payload: orderedData,
  });
};

export const uploadFile = (file) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.post(`upload`, file, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
    dispatch({
      type: GET_INVOICE,
      payload: data.data.fileName,
    });
  } catch (error) {
    console.log(error);
  }
};
