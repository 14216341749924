import { React } from "react";
import Highcharts from "react-highcharts";

const Shops = ({ itemCount }) => {
  const shops = Object.entries(itemCount)
    .sort((a, b) => {
      if (a[1] < b[1]) {
        return 1;
      }
      if (a[1] > b[1]) {
        return -1;
      }
      return 0;
    })
    .map((item) => {
      return item;
    });

  const shopNames = shops.map((item) => {
    return item[0];
  });

  const statistics = shops.map((item) => {
    return item[1];
  });

  const config = {
    chart: {
      type: "bar",
      height: 700,
    },
    tooltip: {
      useHtml: true,
      format: <h1>Hi</h1>,
    },
    xAxis: {
      categories: shopNames,
      tickLength: 20,
      lineWidth: 0,
    },
    colors: ["#5dba2f"],
    title: null,
    plotOptions: {
      series: {
        pointPadding: 0,
      },
    },
    series: [
      {
        data: statistics,
      },
    ],

    function(chart) {
      // on complete
      function noop() {}

      chart.renderer
        .button(
          "<",
          chart.plotLeft - 60,
          chart.plotHeight + chart.plotTop,
          noop
        )
        .addClass("left")
        .add();

      chart.renderer
        .button(
          ">",
          chart.plotLeft + chart.plotWidth + 30,
          chart.plotHeight + chart.plotTop,
          noop
        )
        .addClass("right")
        .add();

      ".left".click(function () {
        chart.xAxis[0].setExtremes(0, 5);
      });
      ".right".click(function () {
        chart.xAxis[0].setExtremes(6, 11);
      });
    },
  };

  return <Highcharts config={config} />;
};
export default Shops;
