import "./BuyForMe.scss";
import { useMemo } from "react";
import { BuyForMeTable } from "../../components";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import AlertDialog from "../../components/dialog/Dialog";
import { Alert } from "@mui/material";

import { tableHeads } from "../../utils/tableHeads";
import {
  getOrgers,
  getReport,
  getReportBalance,
} from "../../store/actions/index";
import {
  rejectOrder,
  buyOrder,
  getEditedOrders,
} from "../../store/actions/ordersAction";

function BuyForMe() {
  const [dialog, setDialog] = useState({
    isOpen: false,
    message: "",
    rejectMessage: false,
    type: "",
  });

  const [checkedDatas, setCheckedDatas] = useState([]);
  const [checkCodes, setCheckCodes] = useState([]);
  const [buyIds, setBuyIds] = useState({ id: [] });
  const [rejectIds, setRejectIds] = useState({ id: [] });
  const [checkedValues, setCheckedValues] = useState([]);
  const [ready, setReady] = useState(false);
  const [alert, setAlert] = useState({
    success: false,
    error: false,
    message: "",
  });

  const data = useSelector((state) => state.OrdersReducer.orders);
  const rejectData = useSelector((state) => state.OrdersReducer.rejectData);

  const purchasedData = useSelector(
    (state) => state.OrdersReducer.purchasedData
  );
  const perPage = useSelector((state) => state.OrdersReducer.perPage);
  const totalData = useSelector((state) => state.OrdersReducer.total);
  const commissionData = useSelector((state) => state.OrdersReducer.commission);

  const navigate = useNavigate();

  const orderId = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrgers(perPage, 1, "", "perPage"));
    !localStorage.getItem("token") && navigate("/login");
    setReady(false);
  }, [rejectData, perPage, purchasedData]);

  const removeItem = (e, id) => {
    e.stopPropagation();
    orderId.current = id;
    setDialog({
      isOpen: true,
      message: "Are you sure you want to delete? 😏",
      rejectMessage: false,
      type: "delete",
    });
  };

  const cancelItem = (e, id) => {
    e.stopPropagation();
    orderId.current = id;
    setDialog({
      isOpen: true,
      message: "Are you sure you want to reject? 😏",
      rejectMessage: true,
      type: "reject",
    });
  };

  const buy = (e, id) => {
    e.stopPropagation();
    orderId.current = id;
    setDialog({
      isOpen: true,
      message: "Are you sure you want to buy? 😊",
      rejectMessage: false,
      type: "buy",
    });
  };

  const updateData = (id) => {
    const editedData = data.find((item) => item.id == id);
    dispatch(getEditedOrders(editedData));
  };

  const handleClose = () => {
    setDialog({ ...dialog, isOpen: false, message: "", rejectMessage: false });
  };

  const handleAgree = (passData, type, id) => {
    setTimeout(() => {
      setDialog({
        ...dialog,
        isOpen: false,
        message: "",
        rejectMessage: false,
      });
    }, 1000);

    switch (type) {
      case "reject":
        setRejectIds(rejectIds.id.push(orderId.current));
        dispatch(
          rejectOrder(
            id == ""
              ? { rejectIds, data: passData }
              : { rejectIds: { id }, data: passData }
          )
        );
        setRejectIds({ id: [] });
        setReady(!ready);
        setCheckedValues([]);

        break;
      case "buy":
        setBuyIds(buyIds.id.push(orderId.current));
        dispatch(buyOrder(buyIds, data));

        setBuyIds({ id: [] });
        setReady(true);
        setCheckedValues([]);

        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="buyForMe-wrapper">
        <div className="buyForMe-wrapper-info">
          {/* {totalData > 0 && <p>Total Price: {totalData}</p>}
          {commissionData > 0 && <p>Commission: {commissionData}</p>} */}
          {/* <button
            onClick={handleReport}
            className="search_wrapper__items_button_reset"
          >
            Report
          </button>
          <button
            onClick={handleReportBalance}
            className="search_wrapper__items_button_reset"
          >
            Report-balance
          </button> */}
        </div>
        <div></div>
        {alert.success && (
          <div className="alert-wrapper">
            <Alert severity="success">Order edited successfully!</Alert>
          </div>
        )}
        {alert.error && (
          <div className="alert-wrapper">
            <Alert severity="error">{alert.message}!</Alert>
          </div>
        )}
        {dialog.isOpen && (
          <div className="mat-dialog-wrapper">
            <AlertDialog
              open={dialog.isOpen}
              handleClose={handleClose}
              message={dialog.message}
              handleAgree={handleAgree}
              rejectMessage={dialog.rejectMessage}
              type={dialog.type}
            />
          </div>
        )}

        <div className="content-table">
          <BuyForMeTable
            ready={ready}
            setReady={setReady}
            checkedValues={checkedValues}
            setCheckedValues={setCheckedValues}
            handleClose={handleClose}
            handleAgree={handleAgree}
            dialog={dialog}
            setDialog={setDialog}
            checkCodes={checkCodes}
            setCheckCodes={setCheckCodes}
            checkedDatas={checkedDatas}
            setCheckedDatas={setCheckedDatas}
            tableHeads={tableHeads}
            title={"Orders"}
            rows={data}
            updateData={updateData}
            action={{
              updateUrl: "../edit-order/",
              update: "Edit",
              delete: "Delete",
              remove: removeItem,
              cancelName: "Reject",
              cancelItem: cancelItem,
              buyName: "Buy",
              buy: buy,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default BuyForMe;
