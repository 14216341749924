export const translateNumber = (input) => {
  const newInput = "" + input;
  return +newInput.replace(/\.(\d{1,2}).*$/, ".$1");
};

export const capitalize = (word) => {
  return word.replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
};
export const getPercent = (number) => {
  if (number) {
    const ziro = 1 + "0".repeat(+number?.toString()?.length - 2);
    const newNumber = (number / Number(ziro))?.toFixed(1);
    return newNumber;
  }
};
