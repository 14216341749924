import { Chart } from "react-google-charts";

import styles from "./pieChart.module.scss";

import upArrow from "../../assets/images/icons/up.svg";

function PieChart({ percent, chartTitle, total, color, bigNumber }) {
  const data = [
    ["", ""],
    ["", total],
    ["", bigNumber - Math.round(total)],
  ];

  const options = {
    legend: "none",

    pieHole: 0.8,

    pieSliceText: "none",
    pieSliceTextStyle: {
      color: "black",
    },

    tooltip: { trigger: "none" },
    sliceVisibilityThreshold: 0.1,

    slices: {
      0: { color: color },
      1: { color: "grey", offset: 0 },
    },
  };
  return (
    <div style={{ position: "relative" }}>
      <Chart
        chartType="PieChart"
        data={data}
        options={options}
        width={"200px"}
        height={"150px"}
      ></Chart>
      <div className={styles.main__pie_total}>
        <p className={styles.main__pie_total_item}> {total}</p>
      </div>
      <div className={styles.main__pie_info}>
        <img src={upArrow} alt="" />
        <p className={styles.main__pie_info_percent}>{percent}</p>
      </div>
      <div className={styles.main__pie_info_title}>{chartTitle}</div>
    </div>
  );
}

export default PieChart;
