import { useState } from "react";
import styles from "./rangeDate.module.scss";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import { Select } from "antd";

const { Option } = Select;

const RangeDate = ({}) => {
  // const [optionValue, setOptionValue] = useState("Today");

  // const handleChange = (value) => {
  //   console.log(value);
  //   setOptionValue(value);
  // };

  // const options = dateRange.map(({ name, id }) => {
  //   return (
  //     <Option key={id} value={name}>
  //       {name}
  //     </Option>
  //   );
  // });

  const [fromDate, setFromDate] = useState(new Date());

  const [toDate, setToDate] = useState(new Date());

  const range = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last Year": [
      moment().subtract(1, "year").startOf("year"),
      moment().subtract(1, "year").endOf("year"),
    ],
  };

  const handleEvent = (event, picker) => {
    setFromDate(picker.startDate._d.toISOString());
    setToDate(picker.endDate._d.toISOString());
  };

  const [state, setState] = useState({
    start: moment().subtract(29, "days"),
    end: moment(),
  });
  const { start, end } = state;
  const handleCallback = (start, end) => {
    setState({ start, end });
  };
  const label =
    start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY");
  return (
    <div className={` ${styles.range}`}>
      <DateRangePicker
        initialSettings={{
          startDate: start.toDate(),
          endDate: end.toDate(),
          ranges: {
            Today: [moment().toDate(), moment().toDate()],
            Yesterday: [
              moment().subtract(1, "days").toDate(),
              moment().subtract(1, "days").toDate(),
            ],
            "Last 7 Days": [
              moment().subtract(6, "days").toDate(),
              moment().toDate(),
            ],
            "Last 30 Days": [
              moment().subtract(29, "days").toDate(),
              moment().toDate(),
            ],
            "This Month": [
              moment().startOf("month").toDate(),
              moment().endOf("month").toDate(),
            ],
            "Last Month": [
              moment().subtract(1, "month").startOf("month").toDate(),
              moment().subtract(1, "month").endOf("month").toDate(),
            ],
          },
        }}
        onCallback={handleCallback}
      >
        <div
          id="reportrange"
          className="col-2"
          style={{
            // background: "#EEEEEE",
            cursor: "pointer",
            padding: "11px 10px",
            border: "none",
            minWidth: "30%",
            maxWidth: "300px",

            borderRadius: "8px",
          }}
        >
          <i className="fa fa-calendar"></i>&nbsp;
          <span>{label}</span> <i className="fa fa-caret-down"></i>
        </div>
      </DateRangePicker>
    </div>
    // {/* <Select
    //   showArrow={false}
    //   defaultValue={optionValue}
    //   style={{
    //     width: 120,
    //   }}
    //   onChange={handleChange}
    // >
    //   {options}
    // </Select> */}
  );
};

export default RangeDate;
