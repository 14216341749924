export const statisticsStatuses = [
  { id: 1, status: "in Process" },
  { id: 2, status: "Needs Confirm" },
  { id: 3, status: "Confirmed" },
  { id: 4, status: "Purchased" },
  { id: 5, status: "Rejected" },
  { id: 6, status: "Cancelled" },
  { id: 7, status: "Delivered" },
  {id: 8, status: "Deleted"}
];
