import { useSelector, useDispatch } from "react-redux";
import "./TableItem.scss";
import { Fragment, useEffect, useState } from "react";
import Star from "../../../assets/svg/prime.svg";
import email from "../../../assets/svg/email.svg";
import arrowDown from "../../../assets/svg/arrow_down.svg";
import edit from "../../../assets/svg/edit.svg";
import reject from "../../../assets/svg/reject.svg";
import buy from "../../../assets/svg/buy.svg";
import { Link } from "react-router-dom";
import { Checkbox, Col, Row } from "antd";

function TableItem({
  rows,
  ready,
  handleClick,
  action,
  tableHeads,
  updateData,
  onChangeChecked,
}) {
  const [isReverce, setIsReverce] = useState(true);

  const [imgSrc, setImgSrc] = useState(``);

  const [isReverceData, setIsReverceData] = useState({
    Id: false,
    "Full Name": false,
    Recipient: false,
    Comment: false,
    "Order Number": false,
    "Total Price": false,
  });

  const [sort, setSort] = useState({ column: null, direction: "desc" });

  const orders = useSelector((state) => state.OrdersReducer.orders);

  const capitalize = (word) => {
    return word.replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
  };

  const handleReverce = (name) => {
    setIsReverce(!isReverce);
    setIsReverceData({ [name]: isReverce });
    orders.reverse();
  };

  const onSort = (column) => (event) => {
    const direction = sort.column
      ? sort.direction === "asc"
        ? "desc"
        : "asc"
      : "desc";

    const sortedData = orders.sort((a, b) => {
      switch (column) {
        case "Full Name":
          setIsReverce(!isReverce);
          setIsReverceData({ [column]: isReverce });
          const nameA = a.customer_full_name;
          const nameB = b.customer_full_name;
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }

          return 0;
        case "Order Number":
          setIsReverce(!isReverce);
          setIsReverceData({ [column]: isReverce });
          const ordA = a.order_number;
          const ordB = b.order_number;
          if (ordA !== null || ordB !== null) {
            if (ordA < ordB) {
              return -1;
            }
            if (ordA > ordB) {
              return 1;
            }
          }

          return 0;
        case "Recipient":
          setIsReverce(!isReverce);
          setIsReverceData({ [column]: isReverce });
          const resA = a.recipient_full_name;
          const recB = b.recipient_full_name;
          if (resA < recB) {
            return -1;
          }
          if (resA > recB) {
            return 1;
          }

          return 0;

        case "Tracking Number":
          setIsReverce(!isReverce);
          setIsReverceData({ [column]: isReverce });
          const trackA = a.tracking_code;
          const trackB = b.tracking_code;
          if (trackA !== null || trackB !== null) {
            if (trackA < trackB) {
              return -1;
            }
            if (trackA > trackB) {
              return 1;
            }
          }

          return 0;

        case "Product":
          setIsReverce(!isReverce);
          setIsReverceData({ [column]: isReverce });
          const prodA = a.product.name;
          const prodB = b.product.name;
          if (prodA < prodB) {
            return -1;
          }
          if (prodA > prodB) {
            return 1;
          }
          return 0;
        case "Comment":
          if (orders.admin_comment !== null) {
            setIsReverce(!isReverce);
            setIsReverceData({ [column]: isReverce });
            const commA = a.admin_comment;
            const commB = b.admin_comment;
            if (commA < commB) {
              return -1;
            }
            if (commA > commB) {
              return 1;
            }
          }
          return 0;
        case "Total Price":
          setIsReverce(!isReverce);
          setIsReverceData({ [column]: isReverce });
          const totalA = a.total_price;
          const totalB = b.total_price;

          if (totalA !== null || totalB !== null) {
            if (Math.round(totalA) < Math.round(totalB)) {
              return -1;
            }
            if (Math.round(totalA) > Math.round(totalB)) {
              return 1;
            }
          }
          return 0;

        case "Created Date":
          setIsReverce(!isReverce);
          setIsReverceData({ [column]: isReverce });
          const createA = a.created_at;
          const createB = b.created_at;
          if (
            new Date(createA).setHours(0, 0, 0, 0) <
            new Date(createB).setHours(0, 0, 0, 0)
          ) {
            return -1;
          }
          if (
            new Date(createA).setHours(0, 0, 0, 0) >
            new Date(createB).setHours(0, 0, 0, 0)
          ) {
            return 1;
          }
          return 0;

        case "Updated Date":
          setIsReverce(!isReverce);
          setIsReverceData({ [column]: isReverce });
          const updateA = a.updated_at;
          const updateB = b.updated_at;
          if (
            new Date(updateA).setHours(0, 0, 0, 0) <
            new Date(updateB).setHours(0, 0, 0, 0)
          ) {
            return -1;
          }
          if (
            new Date(updateA).setHours(0, 0, 0, 0) >
            new Date(updateB).setHours(0, 0, 0, 0)
          ) {
            return 1;
          }
          return 0;
        case "Purchased Date":
          setIsReverce(!isReverce);
          setIsReverceData({ [column]: isReverce });
          const buyA = a.buy_at;
          const buyB = b.buy_at;
          if (buyA < buyB) {
            return -1;
          }
          if (buyA > buyB) {
            return 1;
          }

          return 0;
        default:
          break;
      }
    });

    if (direction === "desc") {
      sortedData.reverse();
    }

    setSort({ column, direction });
  };

  const headDatas = tableHeads.map(({ name, id }) => {
    return (
      <th
        key={id}
        className={
          name == "Id"
            ? "th-id"
            : name == "Full Name"
            ? "th-name"
            : name == "Recipient"
            ? "th-name"
            : name == "Tracking"
            ? "th-tracking"
            : ""
        }
      >
        {name}
        {name == "Action" || name == "Status" || name == "" ? (
          ""
        ) : name == "Full Name" ? (
          <img
            src={arrowDown}
            alt="arrow"
            className={
              isReverceData[name] ? `arrow_down_fullName` : `arrow_up_fullName`
            }
            onClick={onSort(name)}
          />
        ) : name == "Id" ? (
          <img
            src={arrowDown}
            alt="arrow"
            className={
              isReverceData[name] ? `arrow_down_${name}` : `arrow_up_${name}`
            }
            onClick={
              name == "Id"
                ? () => handleReverce(name)
                : name == "Action"
                ? ""
                : onSort(name)
            }
          />
        ) : name == "Order Number" ? (
          <img
            src={arrowDown}
            alt="arrow"
            className={
              isReverceData[name]
                ? `arrow_down_orderNumber`
                : `arrow_up_orderNumber`
            }
            onClick={onSort(name)}
          />
        ) : name == "Tracking Number" ? (
          <img
            src={arrowDown}
            alt="arrow"
            className={
              isReverceData[name]
                ? `arrow_down_trackingCode`
                : `arrow_up_trackingCode`
            }
            onClick={onSort(name)}
          />
        ) : name == "Total Price" ? (
          <img
            src={arrowDown}
            alt="arrow"
            className={
              isReverceData[name] ? `arrow_down_total` : `arrow_up_total`
            }
            onClick={onSort(name)}
          />
        ) : name == "Created Date" ? (
          <img
            src={arrowDown}
            alt="arrow"
            className={
              isReverceData[name] ? `arrow_down_create` : `arrow_up_create`
            }
            onClick={onSort(name)}
          />
        ) : name == "Updated Date" ? (
          <img
            src={arrowDown}
            alt="arrow"
            className={
              isReverceData[name] ? `arrow_down_update` : `arrow_up_update`
            }
            onClick={onSort(name)}
          />
        ) : name == "Purchased Date" ? (
          <img
            src={arrowDown}
            alt="arrow"
            className={
              isReverceData[name] ? `arrow_down_purchase` : `arrow_up_purchase`
            }
            onClick={onSort(name)}
          />
        ) : (
          <img
            src={arrowDown}
            alt="arrow"
            className={isReverceData[name] ? `arrow_down` : `arrow_up`}
            onClick={name == "Action" ? "" : onSort(name)}
          />
        )}
      </th>
    );
  });

  return (
    <table>
      <thead>
        <tr>{headDatas}</tr>
      </thead>
      <tbody>
        {rows.map((row, index) => {
          return (
            <Fragment key={`${row.id}`}>
              <tr className="table-data" onClick={() => handleClick(index)}>
                <td>
                  {ready ? (
                    <input
                      checked={ready && false}
                      className="table-data-checkbox"
                      type="checkbox"
                      name={row.id}
                      value={row.id}
                      onChange={() => onChangeChecked(row.id, index)}
                    />
                  ) : (
                    <input
                      className="table-data-checkbox"
                      type="checkbox"
                      name={row.id}
                      value={row.id}
                      onChange={() => onChangeChecked(row.id, index)}
                    />
                  )}
                </td>

                <td>{row.id}</td>
                <td>
                  <div className="action-wrapper">
                    <div className="action-link-wrapper">
                      <Link to={`/edit-order/${row.id}`}>
                        <img
                          style={{ width: 20, height: 20 }}
                          src={edit}
                          alt="update"
                          onClick={() => {
                            updateData(row.id);
                          }}
                        />
                      </Link>
                    </div>
                    {row.status === "confirmed" && (
                      <div
                        className="action-delete-wrapper"
                        onClick={(e) => action.buy(e, row.id)}
                      >
                        <img
                          style={{ width: 20, height: 20 }}
                          src={buy}
                          alt="buy"
                        />
                      </div>
                    )}
                    <div
                      className="action-delete-wrapper"
                      onClick={(e) => action.cancelItem(e, row.id)}
                    >
                      <img
                        style={{ width: 20, height: 20 }}
                        src={reject}
                        alt="reject"
                      />
                    </div>
                  </div>
                </td>
                {row.customer_is_prime == 0 ? (
                  <td style={{ display: "flex" }}>
                    <p>{row.customer_full_name}</p>
                    <address>
                      <a href={`mailto:${row?.customer_email}`}>
                        <img
                          src={email}
                          alt="email"
                          style={{
                            width: "15px",
                            height: "15px",
                            marginLeft: 10,
                          }}
                        />
                      </a>
                    </address>
                  </td>
                ) : (
                  <td>
                    <div className="td-wrapper">
                      <p>{row.customer_full_name}</p>
                      <img src={Star} alt="Prime User" className="prime_user" />
                      <a href={`mailto:${row?.customer_email}`}>
                        <img
                          src={email}
                          alt="email"
                          style={{
                            width: "15px",
                            height: "15px",
                            marginLeft: 10,
                          }}
                        />
                      </a>
                    </div>
                  </td>
                )}
                <td>{row.recipient_full_name}</td>
                <td>
                  {row.status !== "need to confirm" ? (
                    <span className={`badge-${row.status}`}>
                      {capitalize(row.status)}
                    </span>
                  ) : (
                    <span className={`badge-${row.status}`}>
                      Needs to confirm
                    </span>
                  )}
                </td>
                <td>{row.admin_comment}</td>
                <td>{row.order_number}</td>

                <td>{row.tracking_code}</td>
                <td>
                  <div className="order_product">
                    <a
                      href={row.product.url}
                      target="_blank"
                      className="underline"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {row.shop_name ? (
                        <img
                          onError={(e) => {
                            e.target.src = "/shop/default.svg";
                          }}
                          src={`/shop/${capitalize(row.shop_name)}.svg`}
                          alt={row.shop_name}
                          className="image_data"
                        />
                      ) : (
                        ""
                      )}
                    </a>
                    <span className="product-name" title={row.product.name}>
                      {row.product.name}
                    </span>
                  </div>
                </td>

                <td>{row.total_price}</td>
                <td>{row.created_at}</td>
                <td>{row.updated_at}</td>
                <td>{row.buy_at}</td>
              </tr>
              {row.other && (
                <tr>
                  <td className="colSpan" colSpan="14">
                    <table>
                      <thead>
                        <tr>
                          <th>Color</th>
                          <th>Size</th>
                          <th>Quantity</th>
                          <th>Price</th>
                          <th>Cancel Message</th>
                          <th>Rate</th>
                          <th>Confirmed Price</th>
                          <th>Shipping Price</th>
                          <th>Commission</th>
                          <th>Card</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{row.product.color}</td>
                          <td>{row.product.size}</td>
                          <td>{row.product.quantity}</td>
                          <td>{row.product.price}</td>
                          <td>{row.cancel_message}</td>
                          <td>{row.rate}</td>
                          <td>{row.confirmed_price}</td>
                          <td>{row.shipping_price}</td>
                          <td>{row.commission}</td>
                          <td>{row.card}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              )}
            </Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

export default TableItem;
