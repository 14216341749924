import { AxiosInstance } from "../../utils/AxiosInstance";
import { GET_PERMISSION } from "../types";

export const getPermission = () => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.get("/owner/permissions");
    const dataArr = data.data.map((item) => {
      return item["name"];
    });
    dispatch({
      type: GET_PERMISSION,
      payload: dataArr,
    });
  } catch (error) {
    console.log(error);
  }
};
