import { Login } from "@mui/icons-material";
import { AxiosInstance } from "../../utils/AxiosInstance";
import { LOGIN_USER, LOGIN_USER_ERROR } from "../types";

export const setToken = (params, navigate) => async (dispatch) => {
  try {
    const { data } = await AxiosInstance.post(`login`, params);
    const { token } = data.data;
    localStorage.setItem("token", token);
    dispatch({
      type: LOGIN_USER,
      payload: data,
    });
    navigate("/buy-for-me");
  } catch (error) {
    dispatch({
      type: LOGIN_USER_ERROR,
      payload:
        error.response.data.error.message || error.response.data.error.email[0],
    });
  }
};
