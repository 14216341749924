import "./Navbar.scss";
import Cookies from "js-cookie";
import Logo from "../../../assets/svg/onex-logo.svg";
import { Button } from "../../../components";
import { useNavigate, Link } from "react-router-dom";
import { memo } from "react";

export default function Navbar() {
  const navigate = useNavigate();
  let userName = Cookies.get("user-name");

  const logout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className={"navbar-wrapper"}>
      <div className="burger-btn"></div>
      <div className="navbar-logo-wrapper">
        <Link to="/buy-for-me">
          <img src={Logo} alt="onex-logo" className="navbar-logo" />
        </Link>
      </div>
      <div className="auth-info-wrapper">
        <span className="auth-info">{userName}</span>
        <div className="logout-btn" onClick={() => logout()}>
          <Button name={"Log Out"} type={""} />
        </div>
      </div>
    </div>
  );
}

export const MemoizedNavbar = memo(Navbar);
